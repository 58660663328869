<template>
  
</template>

<script>
export default {
    beforeCreate(){
        this.$router.go(-1)
    }
}
</script>

<style>

</style>